
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/direct-booking-pro",
      function () {
        return require("private-next-pages/direct-booking-pro.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/direct-booking-pro"])
      });
    }
  
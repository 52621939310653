import React from 'react';
import Link from 'next/link';
import { DirectBookingProIcon } from '@components/Icons';
import SocialLinks from '@components/Footer/SocialLinks';

const SimpleFooter = () => {
  return (
    <footer className="bg--white">
      <div className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--large layout-gt-xs-row layout-align-space-between-center">
        <Link
          className="hover-none layout-row m-right--medium"
          href="/direct-booking-pro"
        >

          <DirectBookingProIcon className="w--192 raspberry" />

        </Link>
        <div className="m-top--medium-lt-sm layout-row layout-align-start-center layout-wrap">
          <Link className="meta underline black dot-separator" href="/terms">
            Terms of Service
          </Link>
          <Link className="meta underline black m-right--medium" href="/privacy">
            Privacy Policy
          </Link>
          <span className="meta m-right--medium">© Kopa 2021</span>
          <SocialLinks />
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;

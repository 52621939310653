import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from "next/legacy/image";
import Link from 'next/link';
import { toast } from 'react-toastify';
import Button from '@components/Core/Button';
import RequestDemoDialog from '@components/Dialogs/RequestDemoDialog';
import SimpleFooter from '@components/Footer/SimpleFooter';
import Head from '@components/Core/Head';
import { SawtoothIcon } from '@components/Icons';
import Checklist from '@components/LandingPages/Checklist';
import HowItWorks from '@components/LandingPages/HowItWorks';
import FrequentlyAskedQuestions from '@components/LandingPages/FrequentlyAskedQuestions';
import ImageTextSplit from '@components/LandingPages/ImageTextSplit';
import LandingPageImageList from '@components/LandingPages/LandingPageImageList';
import SubscriptionSavings from '@components/LandingPages/DirectBookingPro/SubscriptionSavings';
import Testimonials from '@components/LandingPages/Testimonials';
import SimpleNav from '@components/Navigation/SimpleNav';
import { withPageWrapper } from '@components/PageWrappers/CorePageWrapper';
import Tag from '@components/Text/Tag';
import SubscriptionPlanConstants from '@domain/Subscriptions/SubscriptionPlanConstants';
import AnalyticsService from '@services/AnalyticsService';
import ImageService from '@services/ImageService';
import LocalStorageService from '@services/LocalStorageService';
import ProfileUtility from '@utilities/ProfileUtility';
import DirectBookingProStatic from '@utilities/static/LandingPages/DirectBookingProStatic';

const DirectBookingProPage = ({ isLoggedIn, user }) => {
  const [showRequestDemoDialog, setShowRequestDemoDialog] = useState(false);
  const isDirectBookingUser = ProfileUtility.isDirectBookingUser(user);
  useEffect(() => {
    const analyticsService = new AnalyticsService();
    analyticsService.handleRouteChange('direct-booking-pro');
  }, []);

  const meta = {
    metaTitle: 'Create your rental property website',
    metaDescription: 'Turn your listings into a website in 4 minutes. Get direct bookings for your rental properties and save $5,400/listing/year from platform service fees.',
    metaUrl: `${process.env.ESPRESSO_BASE}/direct-booking-pro`,
    metaCanonical: `${process.env.ESPRESSO_BASE}/direct-booking-pro`,
    metaImage: 'https://static-images.kopa.co/thumbnails/kopa-direct-booking-website_1200x630.jpg',
    metaImageTwitter: 'https://static-images.kopa.co/thumbnails/kopa-direct-booking-website_600x315.jpg',
  };

  const alreadySubscribed = () => {
    toast(
      <span>
        You already subscribed to Direct Booking Pro! Manage your plan in
        <Link className="m-left--xx-small" href="/settings">
          
          Account Settings
          
        </Link>.
      </span>, {
        type: toast.TYPE.SUCCESS,
      }
    );
  };

  const trackSignupPage = () => {
    LocalStorageService.setItem('signupPage', 'direct-booking-pro');
  };

  const renderCTA = (includeDemoLink = false) => {
    let cta = (
      <Link
        className="button primary"
        href="/signup?role=landlord"
        onClick={trackSignupPage}
      >
        
        Start free trial
        
      </Link>
    );

    if (isDirectBookingUser) {
      cta = (
        <Button className="primary w--100p" onClick={alreadySubscribed}>
          Start free trial
        </Button>
      );
    } else if (isLoggedIn && user.get('is_host')) {
      cta = (
        <Link className="button primary" href="/upgrade">
          
          Start free trial
          
        </Link>
      );
    }

    return (
      <div className="w--100p m-top--x-large">
        { cta }
        { includeDemoLink && (
          <>
            <p className="inline-block m-top--large m-right--xx-small">Not sure if it’s a good fit?</p>
            <Button className="inline link pacific strong underline" onClick={setShowRequestDemoDialog.bind(this, true)}>
              Request a demo
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Head {...meta} />

      <SimpleNav />

      <div className="m-bottom--medium-negative">
        <section className="direct-booking-pro-hero p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs">
          <div className="mw--576 m-horizontal--auto ta-center">
            <Tag state="light-blue" title="New!" />
            <h1 className="m-top--medium">The all-in-one<br />direct booking website.</h1>
            <p className="m-top--large">Turn your listings into a website in 4 minutes. Start building your own brand and enable renters to book directly without all the headache and fees.</p>
            { renderCTA() }
          </div>
          <div className="direct-booking-pro-screenshots relative layout-row layout-align-center-end m-top--xx-large m-top--xxx-large-gt-xs m-horizontal--auto p-top--x-large">
            <Image alt="Beautiful, responsive Kopa custom website" height={636} priority src={`${ImageService.kopaCustomWebsiteResponsive}@3x.png`} width={970} />
          </div>
          <div className="m-top--xx-large-lt-sm m-top--xxx-large-gt-xs">
            <h2 className="ta-center"><span className="raspberry">Create your own website.</span><br />No tech skills needed.</h2>
            <Checklist itemClassName="flex-gt-xs-50" items={DirectBookingProStatic.CHECKLIST_ITEMS} wrapperClassName="mw--768 layout-gt-xs-row layout-wrap m-horizontal--auto m-top--xx-large" />
          </div>
        </section>

        <Testimonials
          headerText="Property managers and owners love it."
          items={DirectBookingProStatic.TESTIMONIAL_ITEMS}
          wrapperClassName="direct-booking-pro-testimonials m-vertical--xx-large-lt-sm m-vertical--xxx-large-gt-xs p-vertical--xxx-large"
        />

        <LandingPageImageList
          items={DirectBookingProStatic.IMAGE_LIST_ITEMS1}
          title={<span>Manage and communicate<br />with all your guests and tenants.</span>}
        />

        <LandingPageImageList
          items={DirectBookingProStatic.IMAGE_LIST_ITEMS2}
          title={<span>Keep track of all your bookings,<br />calendars, and payments.</span>}
        />

        <ImageTextSplit imgAlt="Kopa custom website full mobile-friendly view" imgSrc="kopaCustomWebsiteMobileFull" imgType="jpg" showArrow wrapperClassName="direct-booking-pro-mobile white m-vertical--xx-large-lt-sm m-vertical--xxx-large-gt-xs p-vertical--xxxx-large">
          <>
            <h2>We worked hard<br />so you don’t have to.</h2>
            <p className="m-top--small">Our award-winning designers and engineers perfected the website and ensured it has everything you need for success.</p>
            <h3 className="m-top--x-large m-top--xx-large-gt-xs">Mobile-friendly</h3>
            <p className="m-top--small">Over 50% of renters browse rental properties on their phone, so we made sure your website is mobile-friendly.</p>
            <h3 className="m-top--x-large m-top--xx-large-gt-xs">Optimized for SEO</h3>
            <p className="m-top--small">Each page on your website is optimized through technology and content to enable your website to rank higher in Google search results.</p>
          </>
        </ImageTextSplit>

        <HowItWorks
          description="Through Kopa, generate your website, share it with others, receive inquiries from interested renters, confirm bookings, and get paid."
          items={DirectBookingProStatic.HOW_IT_WORKS_ITEMS}
          title="Creating your website takes 4 minutes."
        />

        <section className="ta-center p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs p-horizontal--large p-horizontal--x-large-gt-xs">
          <SawtoothIcon className="w--80 light-blue" />
          <h1 className="mw--660 raspberry m-top--medium m-horizontal--auto">“I can wow my guests without spending a fortune or having any tech skills.”</h1>
          <p className="m-top--large">— <b>Mike</b>, Kopa host from New Orleans</p>
        </section>

        <SubscriptionSavings />

        <FrequentlyAskedQuestions items={DirectBookingProStatic.FAQ_ITEMS} />

        <section className="direct-booking-pro-bottom m-top--xx-large-lt-sm m-top--xxx-large-gt-xs p-vertical--xx-large p-vertical--xxxx-large-gt-xs p-horizontal--large">
          <div className="mw--576 ta-center bg--white b--rounded m-horizontal--auto m-vertical--x-large p-around--x-large p-around--xx-large-gt-xs">
            <h2>Join the direct booking revolution</h2>
            <p className="m-top--small">Get started with a { SubscriptionPlanConstants.TRIAL_DAYS }-day free trial, or request a demo.</p>
            { renderCTA(true) }
          </div>
        </section>
      </div>

      <RequestDemoDialog isOpen={showRequestDemoDialog} onClose={setShowRequestDemoDialog.bind(this, false)} />

      <SimpleFooter />
    </>
  );
};

DirectBookingProPage.propTypes = {
  // Required
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.get('User').get('isLoggedIn'),
  user: state.get('User').get('object'),
});

export default withPageWrapper(DirectBookingProPage, {
  props: {
    showNav: false,
  },
  mapStateToProps,
});

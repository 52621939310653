import React from 'react';
import SubscriptionCalculator from '@components/Subscriptions/SubscriptionCalculator';
import SubscriptionConstants from '@domain/Subscriptions/SubscriptionConstants';

const SubscriptionSavings = () => {
  return (
    <section className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs layout-gt-xs-row layout-align-space-between-start">
      <div className="m-right--xxx-large-gt-xs">
        <h4>Pricing</h4>
        <h2 className="m-top--small">No commission.<br />No hidden fees.</h2>
        <p className="mw--384 m-top--small">The pricing for the Kopa Direct Booking Pro is simple: pay per listing each month. Never worry about platform fees or service fees that cut into your rental income.</p>
        <h2 className="raspberry m-top--x-large">$5,400</h2>
        <p className="mw--240 meta">average savings in service fees per listing per year</p>
      </div>

      <SubscriptionCalculator
        type={SubscriptionConstants.DEMO}
        wrapperClassName="mw--576 b--gray-2 b-around--xs b--rounded box-shadow--spread m-top--xx-large-lt-sm"
      />
    </section>
  );
};

export default SubscriptionSavings;

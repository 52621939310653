import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { AsYouType } from 'libphonenumber-js';
import Button from '@components/Core/Button';
import Dialog from '@components/Dialogs/Dialog';
import CountrySelect from '@components/Forms/CountrySelect';
import PhoneNumberInput from '@components/Forms/PhoneNumberInput';
import Input from '@components/Forms/Input';
import YouTubeVideo from '@components/Listing/View/YouTubeVideo';
import Actions from '@redux/actions';
import AnalyticsService from '@services/AnalyticsService';

const RequestDemoDialog = ({ countryCodes, getCountryCodes, leadLoading, isOpen, onClose, router, saveLandingPageLeadRequest }) => {
  const [analyticsService] = useState(new AnalyticsService());
  const [formData, setFormData] = useState({ email: null, name: null, country: null, phone_number: null });
  const [step, setStep] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState(Map({}));

  useEffect(() => loadCountryCodes(), [countryCodes, isOpen]);

  const loadCountryCodes = async () => {
    if (countryCodes.size < 1) {
      await getCountryCodes();
    }
    if (countryCodes.size > 0) {
      await setFormData({ ...formData, country: countryCodes.getIn([0, 'id']) });
      setSelectedCountry(countryCodes.get(0));
    }
  };

  const handleInputChange = (key, event) => {
    const { value } = event.target;
    setFormData({ ...formData, [key]: value });
  };

  const handleCountrySelectChange = async (option) => {
    await setFormData({ ...formData, country: option.value });
    setSelectedCountry(countryCodes.find((c) => option.value === c.get('id')));
  };

  const handleNumberInputChange = async (key, event) => {
    let { value } = event.target;

    // Format the phone number
    let formattedValue = new AsYouType('US').input(value);

    // Work around for back spacing the `)` from the area code
    const formattedLastChar = formattedValue[formattedValue.length -1];
    const lastChar = value[value.length -1];
    if (lastChar !== ')' && formattedLastChar === ')' && value.length === 4) {
      formattedValue = value;
    }

    setFormData({ ...formData, phone_number: formattedValue });
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (leadLoading || !(formData.email && formData.name && formData.country && formData.phone_number)) return;

    try {
      await saveLandingPageLeadRequest({
        ...formData,
        anonymous_user_id: analyticsService.getAnonymousUserId(),
        landing_page: 'direct-booking-pro',
        utm_campaign: router.query ? router.query.utm_campaign : undefined,
      });
      setStep(2);
    } catch (error) {
      // Ignore error since redux error handler will grab it
    }
  };

  return (
    <Dialog dialogClassName="dialog-content--medium ta-center" dialogId="change-email-dialog" isOpen={isOpen} onClose={onClose}>
      { step === 1 ? (
        <form name="request-demo-form" onSubmit={submitForm}>
          <h2>Request a demo</h2>
          <Input
            className="m-top--x-large"
            handleChange={handleInputChange}
            hasAsterisk={false}
            id="name-input"
            label="Full Name"
            name="name"
            placeholder="Johnny Rose"
            required
            type="text"
            value={formData.name}
          />
          <Input
            className="m-top--large"
            handleChange={handleInputChange}
            hasAsterisk={false}
            id="email-input"
            label="Email Address"
            name="email"
            placeholder="johnny@gmail.com"
            required
            type="email"
            value={formData.email}
          />
          <CountrySelect
            className="m-top--large"
            fullWidth
            handleChange={handleCountrySelectChange}
            hasAsterisk={false}
            label="Country"
            name="country"
            options={countryCodes.map((c) => ({
              label: c.get('name'),
              value: c.get('id'),
              flag_url: c.get('flag_url'),
            }))}
            required
            type="text"
            value={selectedCountry.get('name') && {
              label: selectedCountry.get('name'),
              value: selectedCountry.get('id'),
              flag_url: selectedCountry.get('flag_url'),
            }}
          />
          <PhoneNumberInput
            areaCode={selectedCountry.get('phone')}
            className="m-top--large"
            handleChange={handleNumberInputChange}
            hasAsterisk={false}
            label="Your phone number"
            name="phone_number"
            placeholder="(123) 456-7890"
            required
            value={formData.phone_number}
          />
          <Button className="primary w--100p m-top--x-large" isLoading={leadLoading} type="submit">
            Submit and view demo
          </Button>
        </form>
      ) : (
        <>
          <h2>View the demo</h2>
          <p className="m-top--small">A link to this video was sent to your email address <b>{ formData.email }</b></p>
          <div className="mw--432 m-top--x-large">
            <YouTubeVideo isLoading={false} src="https://youtu.be/hjnY-h6-Bm4" title="Introduction to Direct Booking Pro" />
          </div>
          <Button className="link pacific strong hover-none m-top--x-large" onClick={onClose}>
            Close
          </Button>
        </>
      )}
    </Dialog>
  );
};

RequestDemoDialog.propTypes = {
  // Required
  countryCodes: PropTypes.object.isRequired,
  getCountryCodes: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  leadLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  saveLandingPageLeadRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  countryCodes: state.get('CountryCodes').get('object'),
  leadLoading: state.get('Lead').get('isLoading'),
});

const mapDispatchToProps = {
  getCountryCodes: Actions.getCountryCodes,
  saveLandingPageLeadRequest: Actions.saveLandingPageLeadRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestDemoDialog)
);

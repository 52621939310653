import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from "next/legacy/image";
import axios from 'axios';
import * as Sentry from '@sentry/node';
import Button from '@components/Core/Button';
import Loader from '@components/Core/Loader';
import { PlayIcon } from '@components/Icons';

// adapted from http://stackoverflow.com/a/5831191/1614967
const YOUTUBE_REGEX = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;

const contains = (str, substr) => {
  return (str.indexOf(substr) > -1);
};

const getIdFromURL = (src) => {
  var id = src ? src.replace(YOUTUBE_REGEX , '$1') : '';

  if (contains(id, ';')) {
    var pieces = id.split(';');

    if (contains(pieces[1], '%')) {
      // links like this:
      // "http://www.youtube.com/attribution_link?a=pxa6goHqzaA&amp;u=%2Fwatch%3Fv%3DdPdgx30w9sU%26feature%3Dshare"
      // have the real query string URI encoded behind a ';'.
      // at this point, `id is 'pxa6goHqzaA;u=%2Fwatch%3Fv%3DdPdgx30w9sU%26feature%3Dshare'
      var uriComponent = decodeURIComponent(pieces[1]);
      id = ('http://youtube.com' + uriComponent).replace(YOUTUBE_REGEX , '$1');
    } else {
      // https://www.youtube.com/watch?v=VbNF9X1waSc&amp;feature=youtu.be
      // `id` looks like 'VbNF9X1waSc;feature=youtu.be' currently.
      // strip the ';feature=youtu.be'
      id = pieces[0];
    }
  } else if (contains(id, '#')) {
    // id might look like '93LvTKF_jW0#t=1'
    // and we want '93LvTKF_jW0'
    id = id.split('#')[0];
  }

  return id;
};

const YouTubeVideo = ({ isSection, isLoading, src, title }) => {
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [videoExists, setVideoExists] = useState(false);

  useEffect(() => {
    if (!thumbnail && src) {
      getThumbnail(getIdFromURL(src));
    }
  }, []);

  const getSrc = (id) => {
    return `https://www.youtube.com/embed/${id}?modestbranding=1&fs=1&controls=0&showinfo=0${isPlaying ? '&autoplay=1' : ''}`;
  };

  const getThumbnail = async (id) => {
    try {
      const response = await axios({
        url: `https://www.googleapis.com/youtube/v3/videos?key=${process.env.GOOGLE_MAPS_API_KEY_OPEN}&part=snippet&id=${id}`,
        method: 'GET',
      });

      Sentry.addBreadcrumb({
        message: 'YouTube thumbnail response',
        category: 'info',
        data: response.data,
      });

      if (!response || (response && !response.data.items.length)) {
        return;
      }

      setIsLoadingThumbnail(false);
      setVideoExists(true);
      if (!response.data.items[0].snippet.thumbnails.maxres) {
        setShowVideo(true);
      } else {
        setThumbnail(response.data.items[0].snippet.thumbnails.maxres.url);
      }
    } catch (error) {
      Sentry.captureException(error);
      return {};
    }
  };

  if (!src || isLoading || !videoExists) return null;

  return (
    <section className={isSection ? 'b--gray-2 b-top--xs m-top--xxxx-large p-top--xxxx-large' : ''}>
      { isSection && (
        <h2 className="m-bottom--xx-large">Video Tour</h2>
      )}
      <div className="sixteen-nine overflow-hidden">
        <div className="content w--100p h--100p">
          { isLoadingThumbnail ? (
            <Loader className="loader--small transform-center-center" />
          ) : (
            <>
              { isPlaying || showVideo ? (
                <iframe className="w--100p h--100p" frameBorder="0" height="100%" src={getSrc(getIdFromURL(src))} title={title} type="text/html" width="100%" />
              ) : (
                <Button className="relative w--100p h--100p" onClick={setIsPlaying.bind(this, true)}>
                  <Image alt="Video thumbnail" layout="fill" objectFit="cover" src={thumbnail} />
                  <PlayIcon className="bg--white icon--64 b--round transform-center-center" />
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

YouTubeVideo.propTypes = {
  // Required
  isLoading: PropTypes.bool.isRequired,
  // Optional
  isSection: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
};

YouTubeVideo.defaultProps = {
  isSection: false,
  src: null,
  title: 'Listing YouTube Video Walk Through',
};

export default YouTubeVideo;

import React from 'react';
import PropTypes from 'prop-types';

const renderSteps = (items) => {
  return (
    <>
      { items.map((step, index) => (
        <div className="flex-gt-sm-25 flex-gt-xs-50 flex-100 m-top--xx-large p-right--large-gt-xs" key={index}>
          <div className="square--36 layout-row layout-align-center-center b--round b-around--sm b--black">
            <span>{ index + 1 }</span>
          </div>
          <h3 className="m-top--medium">{ step.title }</h3>
          <p>{ step.description }</p>
          { step.cta }
        </div>
      ))}
    </>
  );
};

const HowItWorks = ({ description, items, title }) => (
  <section className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs">
    <div className="m-right--x-large-gt-xs">
      <h2>{ title }</h2>
      <p className="mw--576 m-top--small">{ description }</p>
    </div>
    <div className="layout-row layout-align-start-start layout-wrap">
      { renderSteps(items) }
    </div>
  </section>
);

HowItWorks.propTypes = {
  // Required
  description: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  // Optional
  title: PropTypes.string,
};

HowItWorks.defaultProps = {
  title: 'How it works',
};

export default HowItWorks;

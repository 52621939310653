import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checklist from '@components/LandingPages/Checklist';
import ImageService from '@services/ImageService';

class LandingPageImageList extends Component {
  render () {
    const { description, items, title } = this.props;

    return (
      <section className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--xx-large-lt-sm p-vertical--xxx-large">
        <h2 className="mw--720">{title}</h2>
        { description && (
          <p className="mw--576 m-top--medium">{ description }</p>
        )}
        { items.map((item, index) => (
          <div className="m-top--xxxx-large layout-gt-sm-row layout-align-gt-sm-space-between-center" key={index}>
            <div className={`flex-gt-sm-50 ${index % 2 === 1 ? '' : 'flex-order-gt-sm-2'}`}>
              <div className="relative ta-center--sm m-horizontal--auto-lt-md">
                <img alt={item.imgAlt} className={`lazyload mw--100p mh--400 ${item.hasImgShadow ? 'box-shadow--spread-lg b--rounded' : ''}`} data-src={`${ImageService[item.imgSrc]}.${item.imgType || 'png'}`} data-srcset={`${ImageService[item.imgSrc]}@2x.${item.imgType || 'png'} 2x, ${ImageService[item.imgSrc]}@3x.${item.imgType || 'png'} 3x`} />
              </div>
            </div>
            <div className={`flex-gt-sm-50 m-top--x-large-lt-md ${index % 2 === 1 ? 'p-left--xxx-large-gt-xs' : ''}`}>
              <div className="relative z--1 mw--384 m-horizontal--auto-lt-md">
                <h3 className="mw--384">{item.title}</h3>
                <p className="m-top--medium">{item.description}</p>
                { item.unorderedList && (
                  <ul>
                    { item.unorderedList.map((text, i) => (
                      <li key={i}>{ text }</li>
                    ))}
                  </ul>
                )}
                { item.checklist && (
                  <Checklist items={item.checklist} wrapperClassName="m-top--large" />
                )}
                { item.meta && (
                  <p className="meta gray-0 m-top--large">{ item.meta }</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
    );
  }
}

LandingPageImageList.propTypes = {
  // Required
  items: PropTypes.array.isRequired,
  title: PropTypes.node.isRequired,
  // Optional
  description: PropTypes.string,
};

LandingPageImageList.defaultProps = {
  description: null,
};

export default LandingPageImageList;

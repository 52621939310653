import React from 'react';
import PropTypes from 'prop-types';
import Image from "next/legacy/image";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Select, { components } from 'react-select';
import { DropdownIcon } from '@components/Icons';
import GeneralStatic from '@utilities/static/GeneralStatic';

const hideKeyboard = (event) => {
  event.target.setAttribute('readonly', 'readonly'); // Force keyboard to hide on input field.
};

const formatGroupLabel = (data) => (
  <div className="group-styles">
    <h5>{data.label}</h5>
  </div>
);

const CountryRow = ({ data }) => (
  <div className="layout-row layout-align-start-center">
    <Image alt="Country flag" height={24} src={data.flag_url} width={24} />
    <p className="m-left--small m-top--none">{data.label}</p>
  </div>
);

CountryRow.propTypes = {
  data: PropTypes.object.isRequired,
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <DropdownIcon className="icon--14 icon--black" />
  </components.DropdownIndicator>
);

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <CountryRow data={props.data} />
  </components.SingleValue>
);

SingleValue.propTypes = {
  data: PropTypes.object.isRequired,
};

const Option = (props) => (
  <components.Option {...props}>
    <CountryRow data={props.data} />
  </components.Option>
);

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const CountrySelect = ({
  className, error, handleChange, id, label, menuPlacement, hasAsterisk,
  options, showErrors, value, required, fullWidth,
}) => (
  <div className={`${className} select-wrapper ${fullWidth ? 'w--100p' : 'mw--176'}`}>
    { label && <label className={`no-select block m-bottom--xx-small ${required && hasAsterisk ? 'required' : ''}`}>{label}</label> }
    <div className="pseudo-wrapper">
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        components={{ DropdownIndicator, IndicatorSeparator: () => null, Option, SingleValue }}
        formatGroupLabel={formatGroupLabel}
        id={id}
        menuPlacement={menuPlacement || 'bottom'}
        onChange={handleChange}
        onFocus={hideKeyboard}
        options={options}
        placeholder="Select"
        value={value}
      />
    </div>
    {showErrors && error && (
      <TransitionGroup component={null}>
        { showErrors && error && (
          <CSSTransition classNames="item--slide-short" timeout={GeneralStatic.transitionDefault}>
            <p className="meta strong red m-top--xxx-small">{ error }</p>
          </CSSTransition>
        )}
      </TransitionGroup>
    )}
  </div>
);

CountrySelect.propTypes = {
  // Required
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  // Optional
  className: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  menuPlacement: PropTypes.string,
  hasAsterisk: PropTypes.bool,
  required: PropTypes.bool,
  showErrors: PropTypes.bool,
  value: PropTypes.object,
};

CountrySelect.defaultProps = {
  className: '',
  error: null,
  fullWidth: false,
  id: '',
  label: null,
  menuPlacement: 'bottom',
  hasAsterisk: true,
  required: false,
  showErrors: false,
  value: null,
};

export default CountrySelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GeneralStatic from '@utilities/static/GeneralStatic';

class PhoneNumberInput extends Component {
  render () {
    const {
      areaCode, className, error, handleChange, id, label,
      name, required, placeholder, showErrors, value, hasAsterisk,
    } = this.props;

    return (
      <div className={`${className} ${label ? '' : 'no-label '}input-wrapper`}>
        { label && <label className={`no-select block m-bottom--xx-small ${required && hasAsterisk ? 'required' : ''}`}>{label}</label> }
        <div className="layout-row layout-align-start-center">
          { areaCode && <span className="area-code flex b-top--xs b-left--xs b-bottom--xs b--gray-2">+{ areaCode }</span> }
          <input
            className={`phone-number-input ${areaCode ? 'with-area-code' : ''}`}
            id={id}
            name={name}
            onChange={handleChange ? handleChange.bind(this, name) : null}
            placeholder={placeholder}
            required={required}
            value={value || ''}
          />
        </div>
        {showErrors && error && (
          <TransitionGroup component={null}>
            { showErrors && error && (
              <CSSTransition classNames="item--slide-short" timeout={GeneralStatic.transitionDefault}>
                <p className="meta strong red m-top--xxx-small">{ error }</p>
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </div>
    );
  }
}

PhoneNumberInput.propTypes = {
  // Required
  name: PropTypes.string.isRequired,
  // Optional
  areaCode: PropTypes.number,
  className: PropTypes.string,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  hasAsterisk: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  showErrors: PropTypes.bool,
  string: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

PhoneNumberInput.defaultProps = {
  areaCode: null,
  className: '',
  error: '',
  string: null,
  handleChange: null,
  id: '',
  label: null,
  hasAsterisk: true,
  required: false,
  placeholder: '',
  showErrors: false,
  value: null,
};

export default PhoneNumberInput;

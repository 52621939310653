import React from 'react';
import Link from 'next/link';
import { DirectBookingProIcon } from '@components/Icons';
import UserMenu from '@components/Navigation/UserMenu';

const SimpleNav = () => (
  <header className="bg--white">
    <nav className="mw--md h--100p m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs layout-row layout-align-start-center">
      <Link className="hover-none layout-row" href="/direct-booking-pro">

        <DirectBookingProIcon className="w--144 w--176-gt-xs raspberry" />

      </Link>
      <span className="flex" />
      <UserMenu />
    </nav>
  </header>
);

export default SimpleNav;

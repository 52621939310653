import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ArrowForwardIcon, DropdownIcon } from '@components/Icons';
import GeneralStatic from '@utilities/static/GeneralStatic';

const FrequentlyAskedQuestions = ({ items, wrapperClassName }) => {
  const [faqStateMap, setFaqStateMap] = useState({});

  const renderFaqColumn = (items) => {
    return items.map(faq => (
      <div className="m-bottom--x-large" key={faq.key}>
        <div className="b--black b-top--sm p-top--medium">
          <a className="black layout-row layout-align-space-between-center flex" onClick={setFaqStateMap.bind(this, { ...faqStateMap, [faq.key]: !faqStateMap[faq.key] })}>
            <h3>{ faq.title }</h3>
            <span className="icon--12 m-left--medium">
              <DropdownIcon className={`icon-stroke--md icon--black rotate-transition ${faqStateMap[faq.key] ? 'rotate-180' : ''}`} />
            </span>
          </a>
          <TransitionGroup component={null}>
            { faqStateMap[faq.key] && (
              <CSSTransition classNames="item--slide" timeout={GeneralStatic.transitionDefault}>
                <div className="overflow-hidden">
                  <p className="mw--500 m-top--medium">{ faq.description }</p>
                  { faq.link && (
                    <a className="inline-flex underline m-top--small" href={faq.href} rel="noopener noreferrer" target="_blank">
                      { faq.link }
                      <ArrowForwardIcon className="icon--14 icon-stroke--sm m-left--x-small" />
                    </a>
                  )}
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </div>
    ));
  };

  return (
    <section className={`mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs ${wrapperClassName}`}>
      <h2 className="mw--576">Your questions answered</h2>
      <div className="layout-row layout-align-center-start layout-wrap m-top--xx-large">
        <div className="layout-column flex-100 flex-gt-sm-40 flex-gt-xs-50">
          { renderFaqColumn(items.slice(0, Math.round(items.length / 2))) }
        </div>
        <div className="layout-column flex-100 flex-gt-sm-40 flex-gt-xs-50 p-left--x-large-gt-xs">
          { renderFaqColumn(items.slice(Math.round(items.length / 2), items.length)) }
        </div>
      </div>
    </section>
  );
};

FrequentlyAskedQuestions.propTypes = {
  // Required
  items: PropTypes.array.isRequired,
  // Optional
  wrapperClassName: PropTypes.string,
};

FrequentlyAskedQuestions.defaultProps = {
  wrapperClassName: '',
};

export default FrequentlyAskedQuestions;

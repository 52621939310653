import SubscriptionPlanConstants from '@domain/Subscriptions/SubscriptionPlanConstants';

export default {
  CHECKLIST_ITEMS: [
    'No commission, no hidden fees',
    'Online deposit and rent payments',
    'Automated import for your listings',
    'Award-winning designs that convert leads',
    'Reservation inbox and renter management',
    'Unified calendar through iCal syncing',
  ],
  TESTIMONIAL_ITEMS: [
    {
      quote: 'Your website is really slick. You’ve made a much better process for adding a listing than Zillow and all the other rental platforms.',
      name: 'Justin',
      title: 'Host in Seattle',
    }, {
      quote: 'Kopa allows us to find great residents for our properties easily. Kopa renters who inquire are always ready to book and easy to work with.',
      name: 'Astro Living',
      title: 'Host in Bay Area',
    }, {
      quote: 'Kopa is by far the easiest and user-friendly platform on the market. Kopa’s team was very helpful, and we got booking requests in a matter of days!',
      name: 'Daniel',
      title: 'Host in United States',
    }, {
      quote: 'I’m very excited to begin working with your team. I like your model better than Airbnb or VRBO. I hope to build a great partnership.',
      name: 'Jeffrey',
      title: 'Host in Atlanta',
    }, {
      quote: 'In just two weeks, 3 of our 5 rental home listings are through Kopa. This is amazing.',
      name: 'Jim',
      title: 'Host in Seattle',
    }, {
      quote: 'Renting my space for a couple of months at a time instead of nightly makes it much easier to organize my schedule for the entire year.',
      name: 'Joanna',
      title: 'Host in San Francisco',
    }, {
      quote: 'Your support is very impressive! Your team has been amazingly helpful and responsive!',
      name: 'Lillian',
      title: 'Host in Philadelphia',
    }, {
      quote: 'Your team has made it super duper easy to add my spaces to Kopa. It was so much quicker than other websites!',
      name: 'Rick',
      title: 'Host in Texas',
    },
  ],
  IMAGE_LIST_ITEMS1: [
    {
      checklist: ['Extend stays easily', 'Direct booking', 'Background and credit checks'],
      description: 'View your pending inquiries, confirmed stays, and archived requests in one space. It’s easy to accept or decline renters and manage all your tenants.',
      hasImgShadow: true,
      imgAlt: 'Kopa reservation dashboard',
      imgSrc: 'kopaRequestsAndStays',
      imgType: 'jpg',
      title: 'Reservation dashboard',
    }, {
      checklist: ['Respond via text', 'Respond via email', 'Real-time messaging'],
      description: 'View all your messages in one space. Responding is quick and easy.',
      hasImgShadow: true,
      imgAlt: 'Kopa inbox screenshot',
      imgSrc: 'kopaInbox',
      imgType: 'jpg',
      title: 'Inbox',
    }, {
      checklist: ['Get reviews from new renters', 'Add reviews from past renters'],
      description: 'Listings with at least 2 reviews are 4x more likely to get booked than those with 0 reviews. When your listings are booked through your website, renters will write a review at the end of their stay.',
      hasImgShadow: true,
      imgAlt: 'Kopa host reviews',
      imgSrc: 'kopaHostReviews',
      imgType: 'jpg',
      title: 'The central place for your reviews',
    },
  ],
  IMAGE_LIST_ITEMS2: [
    {
      checklist: ['iCal syncing', 'Create unavailable blocks'],
      description: 'View your bookings from all your platforms in one place through calendar syncing.',
      hasImgShadow: true,
      imgAlt: 'Kopa multi-calendar page screenshot',
      imgSrc: 'kopaMultiCalendar',
      imgType: 'jpg',
      title: 'Multi-calendar',
    }, {
      checklist: ['Free bank (ACH) payments', 'Credit card payments with 2.9% fee for renters', 'Special offers and discounts'],
      description: 'Your tenants can send deposit and rent payments in two clicks. Once your tenants move out, return their security deposit through Kopa’s payment system.',
      hasImgShadow: true,
      imgAlt: 'Kopa transactions table',
      imgSrc: 'kopaTransactions',
      imgType: 'jpg',
      title: 'Online payments and reporting',
    },
  ],
  HOW_IT_WORKS_ITEMS: [
    {
      description: 'Onboarding is quick and easy. Enter your email address and location, and you’re set!',
      title: 'Sign up.',
    }, {
      description: 'Almost. In one click, add your listings from another platform. Sync your calendars, and you’re all set!',
      title: 'Snap your fingers.',
    }, {
      description: 'Your main website and each of your listings includes a “Share” link to quickly post on social media or send to potential tenants.',
      title: 'Share your website.',
    }, {
      description: 'View rental applications and accept or decline each renter. After acceptance, the renter pays the deposit to secure the space.',
      title: 'Get direct bookings.',
    },
  ],
  FAQ_ITEMS: [
    {
      key: 'advantage',
      title: 'What’s the biggest advantage of Kopa Direct Booking Pro?',
      description: 'We empower you to create an amazing website to send to any potential tenants or guests, and you don’t have to worry about paying a huge service fee.',
    }, {
      key: 'domain',
      title: 'Can I use my own domain?',
      description: 'Yes, you can use your own domain. Alternatively, you can use one provided by Kopa.',
    }, {
      key: 'differ',
      title: 'How does Kopa Direct Booking Pro differ from the Kopa marketplace?',
      description: 'Direct Booking Pro enables you to create a custom website and get bookings without paying a service fee. All listings will still be posted to the Kopa marketplace but will link to your custom website.',
    }, {
      key: 'duration',
      title: 'Can renters book stays of any length through my website?',
      description: 'Yes. We support short-term, mid-term, and long-term stays. This includes vacation rentals, extended stays, and long-term stays for unfurnished properties. You can set up your listing to support nightly, monthly, and seasonal rates.',
    }, {
      key: 'included',
      title: 'What all is included in the monthly price?',
      description: 'You will get your own custom direct booking website, along with everything you would on the Kopa marketplace including payment processing, iCal syncing, and a reservation inbox.',
    }, {
      key: 'start',
      title: 'How do I get started?',
      description: `Begin a ${SubscriptionPlanConstants.TRIAL_DAYS}-day free trial, or request a demo with a Kopa team member to learn more.`,
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon } from '@components/Icons';
import ImageService from '@services/ImageService';

const ImageTextSplit = ({ children, imgAlt, imgSrc, imgType, showArrow, wrapperClassName }) => {
  return (
    <section className={wrapperClassName}>
      <div className="mw--md m-horizontal--auto layout-gt-xs-row layout-align-start-center">
        <div className="ta-center p-horizontal--large-lt-md p-right--xx-large-gt-sm flex-gt-xs-50">
          <div className="relative inline-block">
            <div className="image-container disable-scrollbars">
              <img alt={imgAlt} className="w--100p block lazyload" data-src={`${ImageService[imgSrc]}.${imgType}`} data-srcset={`${ImageService[imgSrc]}@2x.${imgType} 2x, ${ImageService[imgSrc]}@3x.${imgType} 3x`} />
            </div>
            { showArrow && (
              <ArrowForwardIcon className="icon--18 scroll-indicator absolute a--vertical a--right-lg-negative m-left--x-small" />
            )}
          </div>
        </div>
        <div className="flex-gt-xs-50">
          <div className="p-horizontal--large-lt-md">
            <div className="mw--384 m-top--x-large-lt-sm p-vertical--medium">
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ImageTextSplit.propTypes = {
  children: PropTypes.node.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  // Optional
  imgType: PropTypes.string,
  showArrow: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

ImageTextSplit.defaultProps = {
  imgType: 'png',
  showArrow: false,
  wrapperClassName: '',
};

export default ImageTextSplit;
